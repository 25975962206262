import styled from "styled-components";
import { margin_l, padding_l, padding_m, padding_s } from "../../styles/styles";
import { BLUE_GREY } from "../../styles/colours";
import { LeaderboardPosition } from "./LeaderboardPosition";
import {
  fontSize_l,
  fontSize_m,
  fontSize_s,
  fontSize_xs,
  fontWeight_m,
  Italics,
} from "../../styles/fonts";
import { LeaderboardEntry } from "../../models/leaderboard";
import { translateLeaderboardUtil } from "../../util/leaderboardUtil";
import { LeaderboardDelta } from "./LeaderboardDelta";
import { GLOBAL_MEDIA_QUERIES } from "../../styles/media";
import { useMediaPredicate } from "react-media-hook";

type Props = {
  leaderboard: Array<LeaderboardEntry>;
};

export const Leaderboard = ({ leaderboard }: Props) => {
  const parsedLeaderboard = translateLeaderboardUtil(leaderboard);
  const isSmall = useMediaPredicate(GLOBAL_MEDIA_QUERIES.small);
  const isMedium = useMediaPredicate(GLOBAL_MEDIA_QUERIES.medium);

  let rowHeight = "60px";
  if (isSmall) {
    rowHeight = "30px";
  } else if (isMedium) {
    rowHeight = "40px";
  }

  return (
    <LeaderboardContainer>
      {parsedLeaderboard.map((leaderboardEntry, idx) => (
        <Row
          key={leaderboardEntry.position}
          isLastRow={idx === parsedLeaderboard.length - 1}
          rowHeight={rowHeight}
        >
          <LeaderboardPosition
            position={leaderboardEntry.position}
            rowHeight={rowHeight}
          />
          <RowContent>
            <LeaderboardText>{`${leaderboardEntry.assettoDisplayName}`}</LeaderboardText>
          </RowContent>
          <RowCell>
            <ItalicLeaderboardText>
              {leaderboardEntry.numberOfLaps}
            </ItalicLeaderboardText>
          </RowCell>
          <RowCell>
            <LeaderboardText>
              {leaderboardEntry.laptimeReadable}
            </LeaderboardText>
          </RowCell>
          <LeaderboardDelta
            leaderboardEntry={leaderboardEntry}
            rowHeight={rowHeight}
          />
        </Row>
      ))}
    </LeaderboardContainer>
  );
};

const LeaderboardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${margin_l} 0 0 0;
  user-select: none;
`;

const RowCell = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  background-color: ${BLUE_GREY};

  @media ${GLOBAL_MEDIA_QUERIES.small} {
    width: 50px;
  }

  @media ${GLOBAL_MEDIA_QUERIES.medium} {
    width: 70px;
  }
`;

const RowContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  background-color: ${BLUE_GREY};
  padding: 0 ${padding_s};

  @media ${GLOBAL_MEDIA_QUERIES.large} {
    padding: 0 ${padding_l};
  }
`;

type RowProps = {
  isLastRow: boolean;
  rowHeight: string;
};

const Row = styled.div<RowProps>`
  width: 100%;
  display: flex;
  height: ${({ rowHeight }) => rowHeight};

  margin-bottom: ${({ isLastRow }) => (isLastRow ? "0" : padding_m)};

  @media ${GLOBAL_MEDIA_QUERIES.large} {
    margin-bottom: ${({ isLastRow }) => (isLastRow ? "0" : padding_l)};
  }
`;

export const LeaderboardText = styled.p`
  color: white;
  font-size: ${fontSize_s};
  font-weight: ${fontWeight_m};
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  white-space: nowrap;

  @media ${GLOBAL_MEDIA_QUERIES.small} {
    font-size: ${fontSize_xs};
  }

  @media ${GLOBAL_MEDIA_QUERIES.large} {
    font-size: ${fontSize_l};
  }
`;

const ItalicLeaderboardText = Italics(LeaderboardText);
