import { InfoContainer } from "../components/DownloadCard";
import { ProportionWidthContainer } from "../components/ProportionWidthContainer";
import { TextAndIcon } from "../components/TextAndIcon";
import person from "../images/icons/person-white.svg";
import calendar from "../images/icons/calendar-white.svg";
import { fontSize_xl, fontSize_xxxl, fontWeight_l } from "../styles/fonts";
import styled from "styled-components";

import { margin_l } from "../styles/styles";
import { ImageSpacer, LetterBoxImage } from "../components/LetterBoxImage";
import { RUPERT_BLUE, WHITE } from "../styles/colours";
import { GLOBAL_MEDIA_QUERIES } from "../styles/media";
import ImageGallery from "react-image-gallery";
import { ResponsiveIframe } from "../components/ResponsiveIframe";
import { DownloadButton } from "../components/DownloadButton";
import { formatDate } from "../util/downloadsUtil";
import { useParams } from "react-router-dom";
import { downloads } from "./Downloads";
import { constructUrl } from "../util/url";

export const DOWNLOAD_DIRECTORY_PATH = constructUrl("/api/v1/downloads");

export const Download = () => {
  const { id } = useParams();

  const downloadData = downloads.find((download) => download.id === id);

  return downloadData ? (
    <>
      <LetterBoxImage imageReference={downloadData.primaryImage}>
        <ProportionWidthContainer width="50%">
          <ImageSpacer />
          <BoldDownloadTitle>{downloadData.name}</BoldDownloadTitle>
          <InfoContainer>
            <TextAndIcon
              icon={person}
              text={downloadData.author}
              colour={WHITE}
            />
            <TextAndIcon
              icon={calendar}
              text={formatDate(downloadData.date)}
              colour={WHITE}
            />
          </InfoContainer>
        </ProportionWidthContainer>
      </LetterBoxImage>
      <ProportionWidthContainer width="50%">
        <ImageContainer>
          <ImageGallery
            items={downloadData.gallery.map((imageRef) => ({
              original: imageRef,
            }))}
            autoPlay
            slideInterval={10000}
          />
        </ImageContainer>
        <UserContent
          dangerouslySetInnerHTML={{ __html: downloadData.description }}
        />
        {downloadData.videoLink && (
          <ResponsiveIframe src={downloadData.videoLink} />
        )}
        <DownloadButton
          downloadLink={`${DOWNLOAD_DIRECTORY_PATH}/${downloadData.fileId}`}
        />
      </ProportionWidthContainer>
    </>
  ) : null;
};

const ImageContainer = styled.div`
  width: 60%;
  margin: auto;
  @media ${GLOBAL_MEDIA_QUERIES.small} {
    width: 100%;
  }
`;

const BoldDownloadTitle = styled.p`
  color: white;
  font-size: ${fontSize_xxxl};
  font-weight: ${fontWeight_l};
  margin: 0;
  @media ${GLOBAL_MEDIA_QUERIES.small} {
    font-size: ${fontSize_xl};
  }
`;

const CenteredImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: ${margin_l} 0;
`;

const UserContent = styled.div`
  margin-bottom: ${margin_l};

  h1 {
    color: ${RUPERT_BLUE};
  }
`;
