export type QueryType = {
  small: string;
  medium: string;
  large: string;
};

export const GLOBAL_MEDIA_QUERIES = {
  small: "(max-width: 799px)",
  medium: "(min-width: 800px) and (max-width: 1399px)",
  large: "(min-width: 1400px)",
};
