import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { PageUris } from "../../App";
import { ReactComponent as RupertsRacingLogo } from "../../images/rr-logo.svg";
import { LIGHT_GREY, RUPERT_BLUE } from "../../styles/colours";
import { fontSize_m, fontWeight_l } from "../../styles/fonts";
import { slide as Menu, State } from "react-burger-menu";
import {
  BORDER_WEIGHT_THIN,
  margin_xl,
  padding_m,
  padding_xl,
} from "../../styles/styles";
import { useEffect, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { GLOBAL_MEDIA_QUERIES } from "../../styles/media";

export const NAVBAR_HEIGHT = "100px";

export const NavBar = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const isLarge = !useMediaPredicate(GLOBAL_MEDIA_QUERIES.large);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const menuStateChange = (state: State) => {
    setIsMenuOpen(state.isOpen);
  };

  return isLarge ? (
    <BurgerNavBar>
      <Link to="">
        <UnselectableLogo width={"154px"} height={"50px"} />
      </Link>
      <Menu
        styles={styles}
        isOpen={isMenuOpen}
        onStateChange={menuStateChange}
        right
      >
        <BurgerMenuItems>
          <BurgerMenuItem>
            <StyledLink to="/" $underline={location.pathname === PageUris.Home}>
              Home
            </StyledLink>
          </BurgerMenuItem>
          <BurgerMenuItem>
            <StyledLink to="/server" $underline={location.pathname === PageUris.Server}>
              Server
            </StyledLink>
          </BurgerMenuItem>
          <BurgerMenuItem>
            <StyledLink
              to="/downloads"
              $underline={/^\/downloads.*/.test(location.pathname)}
            >
              Downloads
            </StyledLink>
          </BurgerMenuItem>
          <BurgerMenuItem>
            <StyledA
              rel="noopener noreferrer"
              href="https://discord.rupertsracing.com"
              target="_blank"
            >
              Discord
            </StyledA>
          </BurgerMenuItem>
        </BurgerMenuItems>
      </Menu>
    </BurgerNavBar>
  ) : (
    <StyledNavBar>
      <li>
        <Link to="">
          <UnselectableLogo width={"154px"} height={"50px"} />
        </Link>
      </li>
      <li>
        <StyledLink to="/" $underline={location.pathname === PageUris.Home}>
          Home
        </StyledLink>
      </li>
      <li>
        <StyledLink to="/server" $underline={location.pathname === PageUris.Server}>
          Server
        </StyledLink>
      </li>
      <li>
        <StyledLink
          to="/downloads"
          $underline={/^\/downloads.*/.test(location.pathname)}
        >
          Downloads
        </StyledLink>
      </li>
      <li>
        <StyledA
          rel="noopener noreferrer"
          href="https://discord.rupertsracing.com"
          target="_blank"
        >
          Discord
        </StyledA>
      </li>
    </StyledNavBar>
  );
};

const styles = {
  bmBurgerButton: {
    position: "absolute",
    width: "36px",
    height: "30px",
    right: "36px",
    top: "36px",
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
    top: "0",
  },
  bmMenu: {
    background: "#fff",
    padding: "0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0",
  },
  bmOverlay: {
    background: "rgba(0,0,0,0.3)",
    top: "0px",
    left: "0px",
  },
};

const BurgerNavBar = styled.div`
  background-color: white;
  width: 100%;
  position: relative;
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  padding-left: ${padding_xl};
`;

const StyledNavBar = styled.ul`
  width: 100%;
  height: 100px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
`;

const UnselectableLogo = styled(RupertsRacingLogo)`
  user-select: none;
`;

type StyledLinkProps = {
  $underline: boolean;
};

const StyledLink = styled(Link)<StyledLinkProps>`
  font-size: ${fontSize_m};
  font-weight: ${fontWeight_l};
  text-transform: uppercase;
  color: ${RUPERT_BLUE};
  text-decoration: ${({ $underline }: StyledLinkProps) =>
    $underline ? "underline" : "none"};
`;

const StyledA = styled.a`
  font-size: ${fontSize_m};
  font-weight: ${fontWeight_l};
  text-transform: uppercase;
  color: ${RUPERT_BLUE};
  text-decoration: none;
`;

const BurgerMenuItems = styled.ul`
  width: 100%;
  height: 100%;
  list-style-type: none;

  margin: 0;
  padding: ${padding_m}; ;
`;

const BurgerMenuItem = styled.li`
  padding: ${margin_xl} 0;
  border-bottom: ${BORDER_WEIGHT_THIN} solid ${LIGHT_GREY};
`;
