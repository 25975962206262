import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PageUris } from "../App";
import { Leaderboard } from "../components/leaderboard/Leaderboard";
import {
  LetterBoxImage,
  StraplineContainer,
} from "../components/LetterBoxImage";
import { ProportionWidthContainer } from "../components/ProportionWidthContainer";
import bmwBanner from "../images/bmw-banner.jpg";
import { ChallengeResponse } from "../models/leaderboard";
import { DEEP_BLUE } from "../styles/colours";
import {
  DefaultText,
  fontSize_l,
  fontSize_m,
  fontWeight_s,
  H1,
  ImpactText,
  Italics,
  SubtitleText,
} from "../styles/fonts";
import { constructUrl } from "../util/url";

const impactText = "NEW COMBINATIONS WEEKLY";
const subtitleText =
  "Helping new drivers find their pace while ensuring pros get the challenge they need.";

export const Home = () => {
  const [challenge, setChallenge] = useState<ChallengeResponse | null>(null);

  useEffect(() => {
    axios
      .get(constructUrl("/api/v1/challenges/active"))
      .then((response) => {
        setChallenge(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <LetterBoxImage imageReference={bmwBanner}>
        <ProportionWidthContainer>
          <StraplineContainer>
            <ImpactText>{impactText}</ImpactText>
            <SubtitleText>{subtitleText}</SubtitleText>
            <Link to={PageUris.Server} >
              <ItalicSubtitle>Join the server</ItalicSubtitle>
            </Link>
          </StraplineContainer>
        </ProportionWidthContainer>
      </LetterBoxImage>
      {challenge && (
        <ProportionWidthContainer backgroundColour={DEEP_BLUE}>
          <H1>Assetto Corsa Weekly Challenge</H1>
          <LightText>{challenge.challengeName}</LightText>
          <Leaderboard leaderboard={challenge.leaderboard} />
        </ProportionWidthContainer>
      )}
    </>
  );
};

const LightText = styled(DefaultText)`
  font-weight: ${fontWeight_s};
  font-size: ${fontSize_l};
  color: white;
`;


const ItalicSubtitle = styled(Italics(SubtitleText))`
  font-size: ${fontSize_m};
  text-decoration: underline;
`;