export const margin_s = "10px";
export const margin_m = "15px";
export const margin_l = "25px";
export const margin_xl = "40px";
export const margin_xxl = "60px";

export const padding_s = "10px";
export const padding_m = "15px";
export const padding_l = "25px";
export const padding_xl = "40px";
export const padding_xxl = "60px";

export const BORDER_WEIGHT_THIN = "1px";

export const boxShadow =
  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)";

export const borderRadius_m = "5px";
