import styled from "styled-components";
import { borderRadius_m } from "../styles/styles";

type ScalableSvgProps = {
  height: string;
  borderRadius?: boolean;
};

export const ScalableImgContainer = styled.img<ScalableSvgProps>`
  object-fit: cover;
  max-width: 100%;
  height: ${({ height }) => height};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius_m : 0)};
`;
