import { DownloadData } from "../models/download";
import thumbnail from "../images/downloads/Screenshot_subaru_555_simtraxx_transfagarasan_v0.8_27-3-118-16-0-32.jpg";
import subaruImage from "../images/downloads/Screenshot_subaru_555_simtraxx_transfagarasan_v0.8_27-3-118-16-0-32.jpg";
import subaruInterior from "../images/downloads/20220329151726_1.jpg";
import subaruSlide from "../images/downloads/Screenshot_subaru_555_simtraxx_transfagarasan_v0.8_27-3-118-15-58-6.jpg";
import subaruRally from "../images/downloads/Screenshot_subaru_555_simtraxx_peyre_0.96_25-3-118-18-1-53.jpg";

export const subaruData: DownloadData = {
  id: "subaru.555",
  name: "Subaru STi",
  author: "WILL_001",
  date: "2018-03-21T14:17:30.683+00:00",
  description: `<h1>Impreza 555</h1> 
      The Subaru Impreza debuted in the 1993 WRC, immediately bagging a podium in the 1000 Lakes Rally. Plastered with ‘555’s from British cigarette manufacturer, State Express 555, the Impreza was destined to go down in history as one of the most recognisable rally cars. Group A cars were rapid, so much so that the FIA introduced regulation changes for 1995, mandating more restrictive air-intakes to try and slow the things down. Needless to say, some smart engineering from the Japanese marque saw the introduction of a new boxer engine which was still able to put out over 300 bhp. This power was deployed to the icy roads of the Col de Turini via an intelligent 4WD which used active differentials to enable variable torque split. 
      <br>
      <br>
      The Impreza found its stride in the 1995 season with Colin McRae taking his first and last drivers title, and the 555 Subaru World Rally Team taking the manufacturers championship.
      <h1>The mod</h1> 
      The Subaru Impreza model was taken from DiRT Rally and imported into Assetto by WILL_001. This took a huge amount of work and he made every effort to include details such as the working boost gauge and shift lights. I worked with him to configure the physics and spent countless hours in my proving grounds, the Transfăgărășan, to perfect them. It’s great fun to throw down a tight twisty road, chucking in a handbrake turn here and a clutch kick there! While I wanted to work on a bespoke sound pack, ultimately I didn’t have the time so for now the sounds are taken from the Toyota Celica ST185. 
      <br>
      <br>
      Please bear in mind that this mod was completed over 4 years ago and therefore may not be compatible with all features of Content Manager. If you have any questions or would like to contribute to this mod, please <a href=mailto:smith.rupert@outlook.com>send me an email</a>.
 
      `,
  fileId: "subaru_555.7z",
  thumbnail: thumbnail,
  primaryImage: subaruImage,
  gallery: [subaruInterior, subaruSlide, subaruRally],
  videoLink: "https://www.youtube.com/embed/T2J3Kd0TotI",
};
