import styled from "styled-components";
import { ScalableImgContainer } from "../shared/ScalableImgContainer";
import { H3 } from "../styles/fonts";
import { GLOBAL_MEDIA_QUERIES } from "../styles/media";
import { margin_l, margin_m, margin_s } from "../styles/styles";

type Props = {
  icon: string;
  text: string;
  colour?: string;
};

export const TextAndIcon = ({ icon, text, colour }: Props) => {
  return (
    <TextAndIconContainer>
      <IconContainer>
        <ScalableImgContainer src={icon} height={"15px"} />
      </IconContainer>
      <H3 colour={colour}>{text}</H3>
    </TextAndIconContainer>
  );
};

const IconContainer = styled.div`
  margin: 0 ${margin_s} ${margin_s} 0;
`;

const TextAndIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${margin_l};

  @media ${GLOBAL_MEDIA_QUERIES.small} {
    margin-right: ${margin_m};
  }
`;
