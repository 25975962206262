import styled from "styled-components";
import { MID_GREY, WHITE } from "../styles/colours";
import { fontSize_m } from "../styles/fonts";
import { borderRadius_m, margin_m } from "../styles/styles";

type Props = {
  downloadLink: string;
};

type GenericButtonProps = {
  text: string;
  link: string;
  newWindow?: boolean;
}

export const GenericButton = ({ text, link, newWindow }: GenericButtonProps) => (
  <a href={link} rel={newWindow ? "noopener noreferrer" : undefined}  target={newWindow ? "_blank" : undefined} >
    <StyledButton>{text}</StyledButton>
  </a>
);

export const DownloadButton = ({ downloadLink }: Props) => (
  <a href={downloadLink} download>
    <StyledButton>Download</StyledButton>
  </a>
);

const StyledButton = styled.button`
  margin-bottom: ${margin_m};
  width: 100%;
  height: 40px;
  font-family: "Exo 2", sans-serif;
  font-size: ${fontSize_m};
  color: ${WHITE};
  background-color: ${MID_GREY};
  border-radius: ${borderRadius_m};
  border: 0;

  &:hover {
    cursor: pointer;
  }
`;
