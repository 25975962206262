import styled from "styled-components";
import { margin_xxl } from "../styles/styles";

export type LetterBoxImageProps = {
  imageReference: string;
};

export const LetterBoxImage: React.FC<LetterBoxImageProps> = ({
  imageReference,
  children,
}) => {
  return (
    <Container>
      <Image src={imageReference} />
      {children}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`;

const Image = styled.img`
  filter: brightness(70%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

export const StraplineContainer = styled.div`
  margin: ${margin_xxl} 0;
`;

type SpacerSize = {
  size?: string;
};

export const ImageSpacer = styled.div<SpacerSize>`
  height: ${({ size }) => (size ? size : "300px")};
`;
