import { DownloadData } from "../models/download";
import thumbnail from "../images/downloads/shift-thumb.jpg";

export const razerShiftLights: DownloadData = {
  id: "razer-shift-lights",
  name: "Razer Chroma Shift Lights 1.0",
  author: "Rupert Smith",
  date: "2018-04-29T00:00:00+00:00",
  description: `If you have a Razer Chroma keyboard and/or mouse, then this application will utilize them as RPM indicators. It will also display your current gear to the number row and whether traction control and abs are enabled, represented by an illuminated 't' and 'a' respectively.
    <br>
    <br>
    
    The colour of the RPM indicators are fully customizable and are set within the application and you can also adjust the update frequency (see the included documentation for more information).
    <br>
    <br>
    This program is a standalone application and requires no installation. As such, simply extract the downloaded *.zip file to a location of your choice, e.g. program files, and then you can right click on the *.exe -> Pin to Taskbar/Send To -> Desktop to create a shortcut.
    <br>
    <br>
    See the included documentation for more information.
    <br>
    <br>
    
    Feel free to contact me if you find any problems or have any suggestions: <a href=mailto:smith.rupert@outlook.com >smith.rupert@outlook.com</a>
    <br>
    <br>
    
    Enjoy.
    `,
  thumbnail: thumbnail,
  primaryImage: thumbnail,
  gallery: [thumbnail],
  fileId: "razer-shift-lights.zip",
  videoLink: "https://www.youtube.com/embed/Y4HLRkJRYCg",
};
