import styled from "styled-components";
import { TRANSPARENT } from "../styles/colours";
import { GLOBAL_MEDIA_QUERIES } from "../styles/media";
import {
  padding_l,
  padding_m,
  padding_xl,
  padding_xxl,
} from "../styles/styles";

export type ProportionalWidthContainerProps = {
  backgroundColour?: string;
  innerBackgroundColour?: string;
  width?: string;
};

export const ProportionWidthContainer: React.FC<
  ProportionalWidthContainerProps
> = ({ backgroundColour, innerBackgroundColour, width, children }) => (
  <OuterContainer backgroundColour={backgroundColour}>
    <InnerContainer backgroundColour={innerBackgroundColour} width={width}>
      {children}
    </InnerContainer>
  </OuterContainer>
);

const OuterContainer = styled.div<ProportionalWidthContainerProps>`
  width: 100%;
  background-color: ${({ backgroundColour }) =>
    backgroundColour ? backgroundColour : TRANSPARENT};
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  flex: 1;
`;

type InnerContainerProps = {
  backgroundColour?: string;
  width?: string;
};

const InnerContainer = styled.div<InnerContainerProps>`
  width: ${({ width }) => (width ? width : "70%")};
  padding: ${padding_xl} ${padding_m};
  background-color: ${({ backgroundColour }) =>
    backgroundColour ? backgroundColour : TRANSPARENT};

  @media ${GLOBAL_MEDIA_QUERIES.small} {
    width: 100%;
  }
`;
