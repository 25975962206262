import { LeaderboardEntry } from "../models/leaderboard";

export type ParsedLeaderboardEntry = {
    position: number;
    assettoDisplayName: string;
    laptime: number;
    numberOfLaps: number;
    dateCompleted: string;
    laptimeReadable: string;
    delta: number;
    deltaReadable: string | null;
};

export const translateLeaderboardUtil = (
    leaderboards: Array<LeaderboardEntry>
) => {
    if (leaderboards.length === 0) {
        return [];
    }

    const [leader, ...tail] = leaderboards.map((leaderboardEntry) =>
        convertEntry(leaderboardEntry)
    );
    const addedDelta = tail.map((leaderboardEntry) => {
        const delta = leaderboardEntry.laptime - leader.laptime;
        return {
            ...leaderboardEntry,
            delta: delta,
            deltaReadable: convertDelta(delta),
        };
    });
    return [leader].concat(addedDelta);
};

const convertEntry = (
    leaderboardEntry: LeaderboardEntry
): ParsedLeaderboardEntry => ({
    ...leaderboardEntry,
    assettoDisplayName: checkNameLength(leaderboardEntry.assettoDisplayName),
    laptimeReadable: convertTime(leaderboardEntry.laptime),
    delta: 0,
    deltaReadable: "",
});

export const convertDelta = (time: number): string | null => {
    const timeInSeconds = Math.round(time / 10) / 100;
    const secondsInteger = Math.floor(timeInSeconds);
    const hundredths = Math.round((timeInSeconds - secondsInteger) * 100);

    const readable = `+ ${secondsInteger}.${String(hundredths).padStart(
        2,
        "0"
    )}`;

    // Don't render if greater than +99.99
    return readable.length > 7 ? null : readable;
};

export const convertTime = (time: number): string => {
    const timeInSeconds = Math.round(time / 10) / 100;
    const minutes = Math.floor(timeInSeconds / 60);
    const secondsDecimal = timeInSeconds - 60 * minutes;
    const secondsInteger = Math.floor(secondsDecimal);
    const hundredths = Math.round((secondsDecimal - secondsInteger) * 100);

    return `${minutes}:${String(secondsInteger).padStart(2, "0")}.${String(
        hundredths
    ).padStart(2, "0")}`;
};

export const checkNameLength = (name: string) =>
    name.length > 20 ? `${name.substring(0, 17)}...` : name;
