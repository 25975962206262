import styled from "styled-components";
import leaderboardPositionVector from "../../images/leaderboard/position-container.svg";
import leaderboardPositionSquareVector from "../../images/leaderboard/position-container-square.svg";
import { RUPERT_BLUE } from "../../styles/colours";
import { fontSize_l, fontSize_m, fontSize_xl } from "../../styles/fonts";
import { GLOBAL_MEDIA_QUERIES } from "../../styles/media";
import { ScalableImgContainer } from "../../shared/ScalableImgContainer";
import { useMediaPredicate } from "react-media-hook";

type Props = {
  position: number;
  rowHeight: string;
};

export const LeaderboardPosition = ({ position, rowHeight }: Props) => {
  const isSmall = useMediaPredicate(GLOBAL_MEDIA_QUERIES.small);

  return (
    <LeaderboardPositionContainer>
      <ScalableImgContainer
        src={
          isSmall ? leaderboardPositionSquareVector : leaderboardPositionVector
        }
        height={rowHeight}
      />
      <TextContainer>
        <LeaderboardPositionText>{position}</LeaderboardPositionText>
      </TextContainer>
    </LeaderboardPositionContainer>
  );
};

const LeaderboardPositionContainer = styled.div`
  position: relative;
`;

const TextContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeaderboardPositionText = styled.p`
  color: ${RUPERT_BLUE};
  text-align: center;
  font-size: ${fontSize_l};

  @media ${GLOBAL_MEDIA_QUERIES.small} {
    font-size: ${fontSize_m};
  }

  @media ${GLOBAL_MEDIA_QUERIES.large} {
    font-size: ${fontSize_xl};
  }
`;
