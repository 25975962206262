import styled from "styled-components";
import { ScalableImgContainer } from "../shared/ScalableImgContainer";
import {
  borderRadius_m,
  boxShadow,
  margin_m,
  padding_m,
  padding_s,
} from "../styles/styles";

import person from "../images/icons/person.svg";
import calendar from "../images/icons/calendar.svg";
import { H2 } from "../styles/fonts";
import { Link } from "react-router-dom";
import { TextAndIcon } from "./TextAndIcon";
import { GLOBAL_MEDIA_QUERIES } from "../styles/media";
import { useMediaPredicate } from "react-media-hook";
import { DownloadData } from "../models/download";
import { formatDate } from "../util/downloadsUtil";

type Props = { downloadData: DownloadData };

export const DownloadCard = ({ downloadData }: Props) => {
  const isSmall = useMediaPredicate(GLOBAL_MEDIA_QUERIES.small);
  return (
    <UndecoratedLink to={`/downloads/${downloadData.id}`}>
      <Card>
        <ScalableImgContainer
          src={downloadData.thumbnail}
          height={isSmall ? "100px" : "100%"}
          borderRadius
        />
        <TextContainer>
          <H2>{downloadData.name}</H2>
          <InfoContainer>
            <TextAndIcon icon={person} text={downloadData.author} />
            <TextAndIcon icon={calendar} text={formatDate(downloadData.date)} />
          </InfoContainer>
        </TextContainer>
      </Card>
    </UndecoratedLink>
  );
};

const Card = styled.div`
  background-color: white;
  box-shadow: ${boxShadow};
  padding: ${padding_m};
  border-radius: ${borderRadius_m};
  margin-bottom: ${margin_m};
  display: flex;
  height: 200px;

  @media ${GLOBAL_MEDIA_QUERIES.small} {
    flex-direction: column;
    height: auto;
    padding: ${padding_s};
  }
`;

const TextContainer = styled.div`
  padding: ${padding_m};
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media ${GLOBAL_MEDIA_QUERIES.small} {
    align-items: center;
    padding: ${padding_m} ${padding_m} 0 ${padding_m};
  }
`;

const UndecoratedLink = styled(Link)`
  text-decoration: none;
`;

export const InfoContainer = styled.div`
  display: flex;
`;
