import { DownloadCard } from "../components/DownloadCard";
import { ProportionWidthContainer } from "../components/ProportionWidthContainer";
import { subaruData } from "../download-data/subaru555";
import { razerShiftLights } from "../download-data/razerShiftLights";
import { LIGHT_GREY } from "../styles/colours";

export const downloads = [subaruData, razerShiftLights].sort(
  (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
);

export const Downloads = () => {
  return (
    <ProportionWidthContainer backgroundColour={LIGHT_GREY}>
      {downloads.map((download, idx) => (
        <DownloadCard key={idx} downloadData={download} />
      ))}
    </ProportionWidthContainer>
  );
};
