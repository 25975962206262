import leaderboardDeltaVector from "../../images/leaderboard/delta-container.svg";
import blankLeaderboardDeltaVector from "../../images/leaderboard/blank-delta-container.svg";
import leaderboardDeltaSquareVector from "../../images/leaderboard/delta-container-square.svg";
import blankLeaderboardSquareVector from "../../images/leaderboard/blank-delta-container-square.svg";
import { ParsedLeaderboardEntry } from "../../util/leaderboardUtil";
import styled from "styled-components";
import { LeaderboardText } from "./Leaderboard";
import { ScalableImgContainer } from "../../shared/ScalableImgContainer";
import { useMediaPredicate } from "react-media-hook";
import { GLOBAL_MEDIA_QUERIES } from "../../styles/media";

type Props = {
  leaderboardEntry: ParsedLeaderboardEntry;
  rowHeight: string;
};

export const LeaderboardDelta = ({
  leaderboardEntry: { deltaReadable },
  rowHeight,
}: Props) => {
  const isSmall = useMediaPredicate(GLOBAL_MEDIA_QUERIES.small);

  return (
    <LeaderboardDeltaContainer>
      {deltaReadable ? (
        <>
          <ScalableImgContainer
            src={
              isSmall ? leaderboardDeltaSquareVector : leaderboardDeltaVector
            }
            height={rowHeight}
          />
          <TextContainer>
            <LeaderboardText>{deltaReadable}</LeaderboardText>
          </TextContainer>
        </>
      ) : (
        <ScalableImgContainer
          src={
            isSmall ? blankLeaderboardSquareVector : blankLeaderboardDeltaVector
          }
          height={rowHeight}
        />
      )}
    </LeaderboardDeltaContainer>
  );
};

const LeaderboardDeltaContainer = styled.div`
  position: relative;
`;

const TextContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
