import "./App.css";
import { Routes, Route } from "react-router-dom";
import { NavBar } from "./components/navbar/NavBar";
import { Home } from "./pages/Home";
import { Downloads } from "./pages/Downloads";
import { Download } from "./pages/Download";
import styled from "styled-components";
import { Error } from "./pages/Error";
import { Server } from "./pages/Server";
import { useEffect } from "react";

export enum PageUris {
  Home = "/",
  About = "/about",
  Server = "/server",
  Downloads = "/downloads",
  CatchAll = "*",
}

function App() {
  useEffect(() => {
    document.title = "Rupert's Racing";
  }, []);
  
  return (
    <FullHeight>
      <AppContainer>
        <NavBar />
        <Routes>
          <Route path={PageUris.Home} element={<Home />} />
          <Route path={PageUris.Server} element={<Server />} />
          <Route path={PageUris.Downloads} element={<Downloads />} />
          <Route path={`${PageUris.Downloads}/:id`} element={<Download />} />
          <Route path={PageUris.CatchAll} element={<Error />} />
        </Routes>
      </AppContainer>
    </FullHeight>
  );
}

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
`;

const FullHeight = styled.div`
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
`;
export default App;
