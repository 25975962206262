type Config = {
    fullyQualifiedDomain: string;
};

const dev: Config = {
    fullyQualifiedDomain: "http://localhost:8082",
};

const prod: Config = {
    fullyQualifiedDomain: "",
};

export const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;
