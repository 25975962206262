import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { GenericButton } from "../components/DownloadButton";
import { ProportionWidthContainer } from "../components/ProportionWidthContainer";
import { ServerInfoResponse } from "../models/serverInfo";
import { DefaultText, H2 } from "../styles/fonts";
import { translateServerInfoToCmLink } from "../util/serverUtil";
import { constructUrl } from "../util/url";

export const Server = () => {
    const [serverInfo, setServerInfo] = useState<ServerInfoResponse | null>(null);

    useEffect(() => {
        axios
          .get(constructUrl("/api/v1/server/"))
          .then((response) => {
            setServerInfo(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }, []);

    return serverInfo && (<ProportionWidthContainer width="50%">
            <H2>Join the server</H2>
            <DefaultText>
                If the challenge uses mods then links will be provided in our
                Discord; you'll need to download these first. Hopefully in the
                future we will include links here to make the process easier.
            </DefaultText>
            <DefaultText>
                If you're not using content manager (though I don't know why you
                wouldn't), then search for "{serverInfo.challengeName}" in the Assetto Corsa server
                browser and you should find it.
            </DefaultText>
            <GenericButton
                text={"Join via content manager"}
                link={translateServerInfoToCmLink(serverInfo)}
                newWindow
            />
            <H2>Driving tips for our server</H2>
            <DefaultText>
                <StyledUl>
                    <StyledLi>
                        We embrace all driving abilities however we expect you
                        to drive safely and have basic knowledge of
                        track/driving etiquette
                    </StyledLi>
                    <StyledLi>
                        If you mess up your lap and there is a faster driver
                        behind you, safely move out their way in a predictable
                        manner (i.e. do not unexpectedly brake or change line)
                    </StyledLi>
                    <StyledLi>
                        If you’re approaching a slower driver – they may still
                        be on a personal best lap so do not expect them to move
                        out of your way. Drop back from slower drivers up ahead
                        prior to starting your hot lap as you would in any
                        qualifying session
                    </StyledLi>
                    <StyledLi>Don’t stop on the track</StyledLi>
                    <StyledLi>
                        Drifting is fine provided you don’t get in anyone’s way
                    </StyledLi>
                </StyledUl>
            </DefaultText>
        </ProportionWidthContainer>
    );
};

const StyledUl = styled.ul``;
const StyledLi = styled.li``;
