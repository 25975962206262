import styled, { AnyStyledComponent } from "styled-components";
import { MID_GREY, RUPERT_BLUE } from "./colours";
import { GLOBAL_MEDIA_QUERIES } from "./media";
import { margin_s } from "./styles";

export const fontSize_xs = "10px";
export const fontSize_s = "12px";
export const fontSize_m = "18px";
export const fontSize_l = "25px";
export const fontSize_xl = "35px";
export const fontSize_xxl = "45px";
export const fontSize_xxxl = "72px";

export const fontWeight_s = 200;
export const fontWeight_default = 400;
export const fontWeight_m = 600;
export const fontWeight_l = 700;

export const Italics = (component: AnyStyledComponent) => styled(component)`
  font-style: italic;
`;

export const DefaultText = styled.p`
  margin: 0 0 ${margin_s} 0;
  padding: 0;
  font-size: ${fontSize_m};
  font-weight: ${fontWeight_default};
`;

export const SubtitleText = styled.p`
  color: white;
  font-size: ${fontSize_l};
  margin: 0 0 ${margin_s} 0;
  @media ${GLOBAL_MEDIA_QUERIES.small} {
    font-size: ${fontSize_m};
  }
`;

export const ImpactText = styled.p`
  color: white;
  font-size: ${fontSize_xxxl};
  margin: 0;
  @media ${GLOBAL_MEDIA_QUERIES.small} {
    font-size: ${fontSize_xl};
  }
`;

export const H1 = styled.h1`
  color: white;
  font-size: ${fontSize_xl};
  margin: 0 0 ${margin_s} 0;
  font-weight: ${fontWeight_default};
  text-transform: uppercase;
  @media ${GLOBAL_MEDIA_QUERIES.small} {
    font-size: ${fontSize_xl};
  }
`;

export const H2 = styled.h2`
  color: ${RUPERT_BLUE};

  font-size: ${fontSize_m};
  margin: 0 0 ${margin_s} 0;
  font-weight: ${fontWeight_l};
  @media ${GLOBAL_MEDIA_QUERIES.large} {
    font-size: ${fontSize_xl};
  }
`;

type ConfigurableHeaderProps = {
  colour?: string;
};

export const H3 = styled.h3<ConfigurableHeaderProps>`
  color: ${({ colour }) => (colour ? colour : MID_GREY)};
  font-size: ${fontSize_m};
  margin: 0 0 ${margin_s} 0;
  font-weight: ${fontWeight_s};

  @media ${GLOBAL_MEDIA_QUERIES.large} {
    font-size: ${fontSize_l};
  }
`;

export const StyledGenericLink = styled.a`
  color: ${RUPERT_BLUE};
`;
