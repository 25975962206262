import styled from "styled-components";
import { margin_l } from "../styles/styles";

type Props = {
  src: string;
};

export const ResponsiveIframe = ({ src }: Props) => (
  <Container>
    <StyledIframe src={src} />
  </Container>
);

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: ${margin_l};
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;
