import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  ImageSpacer,
  LetterBoxImage,
  StraplineContainer,
} from "../components/LetterBoxImage";
import { ProportionWidthContainer } from "../components/ProportionWidthContainer";
import horse from "../images/404.jpg";
import { BABY_BLUE } from "../styles/colours";
import {
  fontSize_m,
  fontSize_s,
  ImpactText,
  Italics,
  SubtitleText,
} from "../styles/fonts";
import { GLOBAL_MEDIA_QUERIES } from "../styles/media";

export const Error = () => {
  const { pathname } = useLocation();

  return (
    <LetterBoxImage imageReference={horse}>
      <ProportionWidthContainer>
        <StraplineContainer>
          <ImpactText>Oh no!</ImpactText>
          <SubtitleText>Looks like that page doesn't exist...</SubtitleText>
          <ItalicSubtitle>
            But maybe it should?{" "}
            <StyledA href="mailto:smith.rupert@outlook.com">
              Let us know!
            </StyledA>
          </ItalicSubtitle>
          <ImageSpacer size={"550px"} />
        </StraplineContainer>
      </ProportionWidthContainer>
    </LetterBoxImage>
  );
};

const StyledA = styled.a`
  color: ${BABY_BLUE};
`;

const ItalicSubtitle = styled(Italics(SubtitleText))`
  font-size: ${fontSize_m};
  @media ${GLOBAL_MEDIA_QUERIES.small} {
    font-size: ${fontSize_s};
  }
`;
